import { getLegalDocument, getLegalDocumentForSession, LegalDocumentTypes } from '@/services/api'
import { logger } from '@/utils/logger'

const openDocument = (document: any) => {
    const file = new Blob([document.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)

    logger.log(`Attempting to open fileURL ${fileURL} with window.open`)
    const newWindow = window.open(fileURL, '_blank')

    if (newWindow) {
        logger.log(`Successfully opened ${fileURL} to new window`)
        return
    }

    logger.log(`Unable to open fileURL with window.open, using window.location.href ${fileURL}`)
    window.location.href = fileURL
    logger.log(`Redirected to ${fileURL}`)
}

export const getDocumentForApplicantAndOpen = async (docType: LegalDocumentTypes) => {
    const response = await getLegalDocument(docType)
    openDocument(response)
}

export const getDocumentForSessionAndOpen = async (docType: LegalDocumentTypes) => {
    const response = await getLegalDocumentForSession(docType)
    openDocument(response)
}
