<template>
    <centered-layout>
        <div class="text-center">
            <img
                src="@/assets/images/global/sorryicecream.svg"
                width="200px"
                alt="Dropped Ice Cream"
            >
        </div>
        <div class="text-center">
            <h5 class="fw-light">
                {{ $t('pages.shared.unapproved.header') }}
            </h5>
        </div>
        <p class="text-muted">
            We appreciate your interest in Aven and your time in submitting your application. We have attached an Adverse Action Notice to help you understand potential reasons why you may not have
            been approved. Declined applications can try again six months later.
            <span v-if="isConfirmedFirstLienPosition"> Also attached is your Property Valuation. </span>
        </p>
        <button
            class="btn btn-secondary mb-2"
            @click="getDocument('AdverseActionNotice')"
        >
            {{ $t('pages.shared.unapproved.link') }}
        </button>
        <button
            v-if="isConfirmedFirstLienPosition"
            class="btn btn-secondary mb-2"
            @click="getDocument('PropertyValuation')"
        >
            Download Property Valuation
        </button>
        <a
            class="btn btn-block btn-tertiary"
            href="/support"
        >
            {{ $t('pages.shared.unapproved.contact') }}
        </a>
    </centered-layout>
</template>

<script>
    import { misc } from '@/mixins/misc'
    import CenteredLayout from '@/layouts/Centered'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { i18n } from '@/utils/i18n'

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        mixins: [misc],
        data: function () {
            return {
                isConfirmedFirstLienPosition: !!appSessionStorage.getItem(localStorageKey.isFirstLienPosition),
            }
        },
        mounted: function () {
            this.$logEvent('view_unapproved')
        },
        methods: {
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    this.errorText = ApiErrorHandler(e, 'failed to open pdf documents')
                }
                this.loading = false
                this.loadingTitle = i18n.t('offerPreview.loading')
            },
        },
    }
</script>
